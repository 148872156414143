import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Collapse, IconButton, Box } from '@mui/material';
import { ExpandLess, ExpandMore, Dashboard, Settings, AccountCircle, CoPresent, LaptopChromebook, ChevronLeft, Schedule } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from './User';

const drawerWidth = 240;

const Sidebar = ({ open, handleDrawerClose, variant }) => {
  const { user } = useUser(); // Access the current user's role

  const [openMenu, setOpenMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const location = useLocation(); // Hook to get the current route

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant={variant}
      anchor="left"
      open={open}
    >
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: 1, backgroundColor: 'background.paper' }}>
        <IconButton onClick={handleDrawerClose} sx={{ color: 'text.primary' }}>
          <ChevronLeft />
        </IconButton>
      </Box>

      <List>
        {/* Dashboard with submenu */}
        {/*<ListItem
          button
          selected={location.pathname === '/dashboard'} // Highlight based on route
          onClick={handleMenuClick}
        >
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
          {openMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              selected={location.pathname === '/dashboard/subitem1'}
              sx={{ pl: 4 }}
              component={Link}
              to="/dashboard/subitem1"
            >
              <ListItemText primary="Sub-item 1" />
            </ListItem>
            <ListItem
              button
              selected={location.pathname === '/dashboard/subitem2'}
              sx={{ pl: 4 }}
              component={Link}
              to="/dashboard/subitem2"
            >
              <ListItemText primary="Sub-item 2" />
            </ListItem>
          </List>
        </Collapse>*/}

        {/* Settings */}
        {/*<ListItem
          button
          selected={location.pathname === '/settings'}
          onClick={() => handleListItemClick(1)}
          component={Link}
          to="/settings"
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>*/}

        {/* Aanwezigheden */}
        {['Admin', 'Leraar', 'Secretariaat'].includes(user?.rol) && (
          <ListItem
            button
            selected={location.pathname === '/aanwezigheden'}
            onClick={() => handleListItemClick(1)}
            component={Link}
            to="/aanwezigheden"
          >
            <ListItemIcon>
              <CoPresent />
            </ListItemIcon>
            <ListItemText primary="Aanwezigheden" />
          </ListItem>
        )}

        {/* Show "Lessenrooster" for all users */}
        <ListItem
          button
          selected={location.pathname === '/lessenrooster'}
          onClick={() => handleListItemClick(1)}
          component={Link}
          to="/lessenrooster"
        >
          <ListItemIcon>
            <Schedule />
          </ListItemIcon>
          <ListItemText primary="Lessenrooster" />
        </ListItem>

        {/* Show "Chromebooks" only for Admin */}
        {user?.rol === 'Admin' && (
          <ListItem
            button
            selected={location.pathname === '/chromebooks'}
            onClick={() => handleListItemClick(2)}
            component={Link}
            to="/chromebooks"
          >
            <ListItemIcon>
              <LaptopChromebook />
            </ListItemIcon>
            <ListItemText primary="Chromebooks" />
          </ListItem>
        )}

      </List>
    </Drawer>
  );
};

export default Sidebar;
