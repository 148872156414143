import React, { useEffect }  from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useUser } from '../components/User';
import { useNavigate } from 'react-router-dom'; // Use this for redirection

import { useApiBaseUrl } from '../App';

import '../assets/css/Login.css'; // Import het CSS-bestand

function Login() {
  const { setUser } = useUser(); // Get the setUser function from context
  const navigate = useNavigate(); // React Router's hook for navigation
  const apiBaseUrl = useApiBaseUrl();

  const handleLoginSuccess = async (response) => {
    const user_google = jwtDecode(response.credential);
    const email = user_google.email;

    // Store token in localStorage
    localStorage.setItem('token', response.credential);

    const [email_userid, email_domain] = email.split("@"); // Splits het e-mailadres

    try {
      // Make an API call to get user information based on email
      const res = await axios.get(`${apiBaseUrl}/gebruikers/${email_userid}/${email_domain}`); // Adjust the endpoint as necessary
      const userData = res.data.data; // Expecting { name: "John", surname: "Doe", roles: ["Admin", "Leerkracht"] }

      if (userData.length > 0) {
        setUser({ email, ...userData[0] }); // Set the user data in context
        // Redirect the user to aanwezigheden after successful login
        let lastVisitedRoute = localStorage.getItem('lastVisitedRoute') || '/aanwezigheden';
        if (lastVisitedRoute === '/') {
          lastVisitedRoute = '/aanwezigheden';
        }
        navigate(lastVisitedRoute);
      } else {
        console.error("No access to the system:");

        // Optionally clear the token if login fails
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  /*useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: "830280106769-6r93pade43d01kc8m56i497r4e9p6krk.apps.googleusercontent.com",
      callback: handleLoginSuccess,
    });
    window.google.accounts.id.prompt();  // Shows the One Tap UI
  }, []);*/

  return (
    <div className="login-container">
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={() => console.log('Login failed')}
      />
    </div>
  );
}

export default Login;
