import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Avatar, Typography, Tabs, Tab, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const LeerlingProfielPage = ({ apiBaseUrl }) => {
  const { leerling_id } = useParams();
  const [leerling, setLeerling] = useState(null);
  const [absenceData, setAbsenceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const currentMonthIndex = new Date().getMonth(); // Get the current month index (0-11)
  const months = [
    { name: 'Sep', index: 8 }, // September (0-indexed for JS Date)
    { name: 'Okt', index: 9 }, 
    { name: 'Nov', index: 10 }, 
    { name: 'Dec', index: 11 },
    { name: 'Jan', index: 0 }, // January (0-indexed)
    { name: 'Feb', index: 1 }, 
    { name: 'Maa', index: 2 }, 
    { name: 'Apr', index: 3 }, 
    { name: 'Mei', index: 4 }, 
    { name: 'Jun', index: 5 }
  ];

  //const absenceTabIndex = months.findIndex(month => month.index === currentMonthIndex);
  const [absenceTabIndex, setAbsenceTabIndex] = useState(months.findIndex(month => month.index === currentMonthIndex)); // Initialize with the calculated index


  // Function to fetch leerling details
  const fetchLeerlingDetails = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/leerlingen/${leerling_id}`);
      const data = await response.json();
      setLeerling(data.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching leerling details:', error);
      setLoading(false);
    }
  };

  // Function to fetch absence data for the selected month
  const fetchAbsenceData = async (month) => {
    try {
      const response = await fetch(`${apiBaseUrl}/aanwezigheden/leerling/${month}/${leerling_id}`);
      const data = await response.json();
      setAbsenceData(data.data);
    } catch (error) {
      console.error('Error fetching absence data:', error);
    }
  };

  /*const handleTabChange = (event, newValue) => setTabIndex(newValue);*/
  // Handle tab change for absences
  const handleAbsenceTabChange = (event, newValue) => {
    setAbsenceTabIndex(newValue);
    fetchAbsenceData(months[newValue].index + 1); // Fetch data for the selected month (1-indexed for the API)
  };

  useEffect(() => {
    fetchLeerlingDetails();
    fetchAbsenceData(months[0].index + 1); // Fetch current month data on mount
  }, []);

  // Calculate age
  const birthDate = new Date(leerling?.geboortedatum);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Check if the current date is before the birthday this year
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const formattedBirthDate = birthDate.toLocaleDateString('nl-BE', options);

  if (!leerling) return <div>Loading...</div>;

  // Status kleuren
  const getStatusColor = (status) => {
    switch (status) {
      case 'A': //Aanwezig
        return 'lightgreen';
      case 'AF': //Afwezig
        return 'LightCoral';
      case 'LZ': //Te laat ZR
        return 'Gold';
      case 'LM': //Te laat MR
        return 'Gold';
      case 'EZ': //Extreem te laat zonder reden
        return 'orange';
      case 'EM': //Extreem te laat met reden
        return 'orange';
      default:
        return 'white';
    }
  };

  return (
    <div>
      {/* Top Section: Three Column Layout */}
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={4}>
          <Typography variant="h6">Naam: {leerling.naam}</Typography>
          <Typography variant="h6">Voornaam: {leerling.voornaam}</Typography>
          <Typography variant="body1">Geslacht: {leerling.geslacht}</Typography>
        </Grid>

        {/* Middle Column */}
        <Grid item xs={4}>
          <Typography variant="body1">Geboortejaar: {formattedBirthDate} (Leeftijd: {age})</Typography>
          <Typography variant="body1">Klas: {leerling.klas}</Typography>
          <Typography variant="body1">Coach: {leerling.coach}</Typography>
        </Grid>

        {/* Right Column (Photo) */}
        <Grid item xs={4} container justifyContent="flex-end">
          <Avatar 
            alt={leerling.voornaam} 
            src={leerling.photoUrl || '/path/to/default/photo.jpg'} 
            sx={{ width: 100, height: 100 }} 
          />
        </Grid>
      </Grid>

      {/* Address Section with Tabs */}
      {/*<Box mt={4}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Leerling" />
          {leerling.ouders && leerling.ouders.map((ouder, index) => (
            <Tab key={index} label={`Ouder ${index + 1}`} />
          ))}
          {leerling.ondersteuners && leerling.ondersteuners.map((ondersteuner, index) => (
            <Tab key={index} label={`Ondersteuner ${index + 1}`} />
          ))}
        </Tabs>*/}

        {/* Tab Panels */}
        {/*<Box mt={2}>
          {tabIndex === 0 && (
            <Card>
              <Typography variant="body1">Adres: {leerling.adres}</Typography>
              <Typography variant="body1">Email: {leerling.email}</Typography>
              <Typography variant="body1">Telefoon: {leerling.telefoon}</Typography>
            </Card>
          )}

          {leerling.ouders && leerling.ouders.map((ouder, index) => (
            tabIndex === index + 1 && (
              <Card key={index}>
                <Typography variant="body1">Naam: {ouder.naam}</Typography>
                <Typography variant="body1">Adres: {ouder.adres}</Typography>
                <Typography variant="body1">Telefoon: {ouder.telefoon}</Typography>
              </Card>
            )
          ))}

          {leerling.ondersteuners && leerling.ondersteuners.map((ondersteuner, index) => (
            tabIndex === leerling.ouders.length + index + 1 && (
              <Card key={index}>
                <Typography variant="body1">Naam: {ondersteuner.naam}</Typography>
                <Typography variant="body1">Email: {ondersteuner.email}</Typography>
              </Card>
            )
          ))}
        </Box></div>
      </Box>*/}

      {/* Absence Section */}
      <Box mt={4}>
        <Grid container spacing={2}>
          {/* Left Column: Absence Overview */}
          <Grid item xs={4}>
            <Typography variant="h6">Afwezigheidsoverzicht</Typography>
            <Typography variant="body1">Afwezig: {leerling.afwezigheden?.filter((item) => item.status === 'AF').length || 0}</Typography>
            <Typography variant="body1">Te laat ZR: {leerling.afwezigheden?.filter((item) => ['LZ', 'EZ'].includes(item.status)).length || 0}</Typography>
            <Typography variant="body1">Te laat MR: {leerling.afwezigheden?.filter((item) => ['LM', 'EM'].includes(item.status)).length || 0}</Typography>
          </Grid>

          {/* Right Column: Absences by Month */}
          <Grid item xs={8}>
            <Tabs value={absenceTabIndex} onChange={handleAbsenceTabChange}>
              {months.map((month) => (
                <Tab key={month.index} label={month.name} />
              ))}
            </Tabs>

            {/* Tab Panels */}
            <Box mt={2}>
              <Table>
                <TableHead>
                  <TableRow>
                    {['Ma', 'Di', 'Wo', 'Do', 'Vr'].map((day) => (
                      <TableCell key={day} style={{ textAlign: 'center', fontWeight: 'bold' }}>{day}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                  <TableBody>
                  {/* Create rows for each week (assuming up to 6 weeks in a month view) */}
                  {Array.from({ length: 5 }, (_, weekIndex) => (
                    <TableRow key={weekIndex}>
                      {/* Loop through the weekdays (Monday to Friday) */}
                      {Array.from({ length: 5 }, (_, dayIndex) => {
                        // Calculate the first day of the selected month
                        const firstDayOfMonth = new Date(2024, months[absenceTabIndex].index, 1);
                        
                        // Determine the current date by adding days to the first day of the month
                        const currentDate = new Date(firstDayOfMonth);
                        const adjustedDayIndex = weekIndex * 7 + dayIndex; // Each week adds 7 days
                        
                        // Adjust to get the correct weekday in the calendar grid (Monday as first day)
                        currentDate.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + 1 + adjustedDayIndex);
                        
                        // Determine if the current date belongs to the selected month
                        const isCurrentMonth = currentDate.getMonth() === months[absenceTabIndex].index;

                        // Render the day cell; if it's not part of the current month, leave it empty
                        if (!isCurrentMonth) {
                          return <TableCell key={dayIndex}></TableCell>;
                        }

                        // Get the day number
                        const dayNumber = currentDate.getDate();

                        // Filter lessons for the current day
                        const lessonsForDay = absenceData.filter(
                          afw => new Date(afw.lesdatum).toDateString() === currentDate.toDateString()
                        );

                        // Get status for lesson periods (lesuur 1 and 5)
                        const lesuur1Status = lessonsForDay.find(afw => afw.lesuur === '1')?.status || '-';
                        const lesuur5Status = lessonsForDay.find(afw => afw.lesuur === '5')?.status || '-';

                        return (
                          <TableCell key={dayIndex} style={{ textAlign: 'center', padding: '10px' }}>
                            <Box
                              style={{
                                cursor: 'pointer', // Indicates interactiveness
                                transition: 'background-color 0.3s ease', // Smooth hover effect
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.parentNode.style.backgroundColor = '#f0f0f0';
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.parentNode.style.backgroundColor = 'transparent';
                              }}
                            >
                              {/* Center the date */}
                              <Typography variant="h6" style={{ marginBottom: '3px', fontWeight: 'bold', fontSize: 15 }}>
                                {dayNumber}
                              </Typography>
                              {/* Status 1 */}
                              <span
                                style={{
                                  backgroundColor: getStatusColor(lesuur1Status),
                                  width: '30px',
                                  height: '20px',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                  lineHeight: '20px',
                                  borderRadius: '5px',
                                  margin: '0 2px',
                                }}
                              >
                                {lesuur1Status}
                              </span>{' '}
                              |{' '}
                              {/* Status 5 */}
                              <span
                                style={{
                                  backgroundColor: getStatusColor(lesuur5Status),
                                  width: '30px',
                                  height: '20px',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                  lineHeight: '20px',
                                  borderRadius: '5px',
                                  margin: '0 2px',
                                }}
                              >
                                {lesuur5Status}
                              </span>
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default LeerlingProfielPage;
