import React, { useState, createContext, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useMediaQuery, Box, CssBaseline, Toolbar } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { UserProvider, useUser } from './components/User';

import Login from './pages/Login';

import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';
import LeerlingProfielPage from './pages/LeerlingProfiel';
import AanwezighedenPage from './pages/Aanwezigheden';
import LessenroosterPage from './pages/Lessenrooster';
import ChromebooksPage from './pages/Chromebooks';

// Create the ApiBaseUrlContext
const ApiBaseUrlContext = createContext();

export function useApiBaseUrl() {
  return useContext(ApiBaseUrlContext); // Custom hook to use the API base URL
}

function App() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [open, setOpen] = useState(false);
  
  const isMobile = useMediaQuery('(max-width: 768px)'); // Detect mobile devices

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (isMobile) {
      setOpen(false);  // Close only on mobile devices
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      //const decodedUser = jwtDecode(token);
      // Optionally, you could also make an API call to validate the token
      //setUser("decodedUser");
      
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId="830280106769-6r93pade43d01kc8m56i497r4e9p6krk.apps.googleusercontent.com">
      <ApiBaseUrlContext.Provider value={apiBaseUrl}>
      <UserProvider>
        <Router>
          <AuthGuard allowedRoles={[]}>
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* Navbar */}
                <Navbar handleDrawerOpen={handleDrawerOpen} />

                {/* Sidebar */}
                <Sidebar
                  open={open}
                  handleDrawerClose={handleDrawerClose}
                  variant={isMobile ? 'temporary' : 'permanent'} // Permanent for large screens
                />

                {/* Main Content with Routes */}
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <Toolbar />
                  <Routes>
                  <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/dashboard/subitem1" />
                    <Route path="/dashboard/subitem2" />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/leerling/:leerling_id" element={
                      <AuthGuard allowedRoles={['Admin', 'Leraar', 'Secretariaat']}>
                        <LeerlingProfielPage apiBaseUrl={apiBaseUrl} />
                      </AuthGuard>
                      } />
                    <Route path="/aanwezigheden" element={
                      <AuthGuard allowedRoles={['Admin', 'Leraar', 'Secretariaat']}>
                        <AanwezighedenPage apiBaseUrl={apiBaseUrl} />
                      </AuthGuard>
                      } />
                    <Route path="/lessenrooster" element={
                      <AuthGuard allowedRoles={['Admin', 'Leraar', 'Secretariaat']}>
                        <LessenroosterPage apiBaseUrl={apiBaseUrl} />
                      </AuthGuard>
                      } />
                    <Route path="/chromebooks" element={
                      <AuthGuard allowedRoles={['Admin', 'Secretariaat']}>
                        <ChromebooksPage apiBaseUrl={apiBaseUrl} />
                      </AuthGuard>
                      } />

                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Box>
              </Box>
            </AuthGuard>
        </Router>
      </UserProvider>
      </ApiBaseUrlContext.Provider>
     </GoogleOAuthProvider>
  );
}

// Higher-order component to handle authentication at the app level
function AuthGuard({ children, allowedRoles }) {
  const { user } = useUser();
  const location = useLocation();

  localStorage.setItem('lastVisitedRoute', location.pathname);

  // If no user, redirect to Login
  if (!user) {
    return <Login />;
  }

  // Functie om toegang te controleren op basis van rollen
  const hasAccess = (allowedRoles) => {
    if (!user || !user.rol) return false; // Controleer of de gebruiker bestaat en een rol heeft
    // Zorg ervoor dat user.rol een array is and user? checkt of het niet leeg is. 
    const userRoles = user?.rol? (user.rol.includes(',') ? user.rol.split(',').map(role => role.trim()) : [user.rol.trim()]): [];
    // Controleer of een van de toegestane rollen overeenkomt met de gebruikersrollen
    //Als de toegestane rollen leeg zijn, is elke user toegelaten. anders check de doorgegeven array wie er wel toegang heeft.
    return allowedRoles.length === 0 || allowedRoles.some(role => userRoles.includes(role));
  };

  if (!hasAccess(allowedRoles)) {
    return <Navigate to="/" />; // Stuur door als de gebruiker geen toegang heeft
  }

  // If the user is authenticated, render the app's content
  return children;
}

export default App;
