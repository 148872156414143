import React, { useState, useEffect } from 'react';
import { TextField, Button, Autocomplete, Table, TableBody, TableCell, TableHead, TableRow, Modal, Box, CircularProgress } from '@mui/material';

import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie

const LessenroosterPage = ({ apiBaseUrl }) => {
  //const [project, setProject] = useState('');
  //const [weekNumber, setWeekNumber] = useState('');
  const [project, setProject] = useState(Cookies.get('project') || '');
  const [weekNumber, setWeekNumber] = useState(Cookies.get('weekNumber') || '');
  const [personnel, setPersonnel] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState(null);
  const [timetable, setTimetable] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const [allData, setAllData] = useState([]);

  // Handle modal open and close for showing students
  const handleOpen = (subject) => {
    setSelectedSubject(subject);
    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);

  // Fetch personnel based on projectweek and projectnummer
  const fetchPersonnel = async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const response = await axios.get(`${apiBaseUrl}/lessenrooster/lkr/${project}/${weekNumber}`);
      if (response.data.status === 201) {
        setAllData(response.data.data);  // Store the entire response
        // Filter unique personnel based on the 'acro' field
        const uniquePersonnel = response.data.data.filter((person, index, self) =>
          index === self.findIndex((p) => p.acro === person.acro)
        );
        setPersonnel(uniquePersonnel);  // Set unique personnel for dropdown
      } else {
        console.error('Failed to fetch personnel');
      }
    } catch (error) {
      console.error('Error fetching personnel:', error);
    } finally {
      setLoading(false); // Set loading to false when fetching completes
    }
  };

  // Handle form submission to fetch personnel
  const handleSubmit = (e) => {
    e.preventDefault();
    // Save the selected project and weekNumber in cookies
    Cookies.set('project', project, { expires: 7 });  // Save for 7 days
    Cookies.set('weekNumber', weekNumber, { expires: 7 });  // Save for 7 days
    fetchPersonnel();
  };

  // Automatically fetch personnel if project and weekNumber are already set in cookies
  useEffect(() => {
    if (project && weekNumber) {
      fetchPersonnel();
    }
  }, []);  // Empty dependency array ensures this only runs once on component mount

  // Create the timetable for the selected personnel
  useEffect(() => {
    if (selectedPersonnel) {
      const groupedTimetable = {};
      allData
        .filter(lesson => lesson.acro === selectedPersonnel.acro)  // Only include lessons for the selected personnel
        .forEach((lesson) => {
          const day = new Date(lesson.lesdatum).toLocaleDateString('en-US', { weekday: 'long' });
          if (!groupedTimetable[day]) {
            groupedTimetable[day] = Array(7).fill(null); // 7 lessons per day
          }
          groupedTimetable[day][lesson.lesuur - 1] = lesson.vak_groep; // Adjust index to 0-based
        });
      setTimetable(groupedTimetable);  // Set timetable for display
    }
  }, [selectedPersonnel, allData]);  // Run effect when personnel or allData changes
  
  return (
    <div>
      {/* Form for filtering by project week and project number */}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Project"
          value={project}
          onChange={(e) => setProject(e.target.value)}
          required
        />
        <TextField
          label="Weeknummer"
          value={weekNumber}
          onChange={(e) => setWeekNumber(e.target.value)}
          required
        />
        <Button type="submit" variant="contained">Zoeken</Button>
      </form>

      {/* Loading indicator */}
      {loading && <CircularProgress />}

      {/* Personnel filter */}
      {!loading && personnel.length > 0 && (
        <Autocomplete
          options={personnel}
          getOptionLabel={(option) => option.acro}
          onChange={(event, newValue) => setSelectedPersonnel(newValue)}
          renderInput={(params) => <TextField {...params} label="Personeel" variant="outlined" />}
        />
      )}

      {/* Timetable display */}
      {selectedPersonnel && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lesuur</TableCell>
              <TableCell>Maandag</TableCell>
              <TableCell>Dinsdag</TableCell>
              <TableCell>Woensdag</TableCell>
              <TableCell>Donderdag</TableCell>
              <TableCell>Vrijdag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 7 }, (_, lessonHour) => (
              <TableRow key={lessonHour}>
                <TableCell>{`${lessonHour + 1}`}</TableCell>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => {
                  const lesson = timetable[day] && timetable[day][lessonHour];
                  const studentCount = lesson
                    ? allData
                        .find((lessonData) => lessonData.vak_groep === lesson && lessonData.acro === selectedPersonnel.acro)
                        ?.leerlingen.length
                    : 0;

                  return (
                    <TableCell key={day}>
                      {lesson ? (
                        <Button onClick={() => handleOpen(lesson)}>
                          {`${lesson} (${studentCount})`}
                        </Button>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

        {/* Modal for displaying students */}
        <Modal open={open} onClose={handleClose}>
          <Box 
            sx={{ 
              padding: 4, 
              backgroundColor: 'white', 
              margin: 'auto', 
              maxWidth: 400, 
              maxHeight: '80vh', // Set max height based on viewport height (80% of the screen height)
              overflowY: 'auto' // Enable vertical scrolling
            }}
          >
            <h2>{selectedSubject}</h2>
            <ol>
              {allData
                .find((lesson) => lesson.vak_groep === selectedSubject && lesson.acro === selectedPersonnel.acro)?.leerlingen
                .map((student, index) => (
                  <li key={index}>{student.naam} - {student.klas}</li>
              ))}
            </ol>
            <Button onClick={handleClose}>Close</Button>
          </Box>
        </Modal>
    </div> 
  );
};

export default LessenroosterPage;
